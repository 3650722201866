/*
 * @Description: 全局枚举数据
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
/**
 * 考试状态
 */
export const EXAMSTATUS = {
	/**
	 * 全部
	 * @value 0
	 */
	'WHOLE': 0,
    /**
	 * 进行中
	 * @value 2
	 */
    'INPROGRESS': 2,
    /** 
	 * 未开始
	 * @value 1
	 */
    'NOTSTARTED': 1,
    /**
	 * 已结束
	 * @value 3
	 */
    'CLOSED': 3,
	/**
	 * 禁用
	 * @value 4
	 */
	'DISABLED': 4
}
/**
 * 进入考试状态
 */
export const JOINEXAMSTATUS = {
	/**
	 * 全部
	 * @value 0
	 */
	'WHOLE': 0,
	/**
	 * 可以进入考场状态1
	 * @value 1
	 */
    'INPROGRESS': 1,
    /** 
	 * 可以进入考场状态2
	 * @value 2
	 */
    'NOTSTARTED': 2,
    /**
	 * 已结束
	 * @value 3
	 */
    'CLOSED': 3
}
/**
 * 输入框类型
 */
export const INPUTBOXTYPE = {
    /**
	 * 文本输入框
	 * @value TEXT
	 */
    'TEXT': 'text',
    /**
	 * 密码输入框
	 * @value PASSWORD
	 */
    'PASSWORD': 'password',
	/**
	 * 验证码登录
	 * @value code
	 */
	'CODE': 'code',
	/**
	 * 下拉
	 * @value dropDown
	 */
	'DROPDOWN': 'dropDown',
	/**
	 * 防水码
	 * @value waterproofCode
	 */
	'WATERPROOFCODE': 'waterproofCode'
}
/**
 * 登录类型
 */
export const LOGINTYPE = {
    /**
	 * 密码登录
	 * @value 1
	 */
    'PASSWORD': 1,
    /**
	 * 验证码登录
	 * @value 2
	 */
    'VERIFICATIONCODE': 2,
    /**
	 * 微信扫码登录
	 * @value 3
	 */
    'SCANCODE': 3
}
/**
 * 验证码类型
 */
export const CODETYPE = {
	/**
	 * 注册
	 * @value register
	 */
	'REGISTER': 'register',
	/**
	 * 修改密码
	 * @value resetpwd
	 */
	'RESETPWD': 'resetpwd',
	/**
	 * 修改手机号
	 * @value editmobile
	 */
	'EDITMOBILE': 'editmobile',
	/**
	 * 忘记密码
	 * @value forgotpwd
	 */
	'FORGOTPWD': 'forgotpwd',
	/**
	 * 登录
	 * @value login
	 */
	'LOGIN': 'login',
	/**
	 * 修改给邮箱发送验证码
	 * @value editemail
	 */
	"EMAIL": 'editemail',
	/**
	 * 新增给邮箱发送验证码
	 * @value bindemail
	 */
	"ADDEMAIL": 'bindemail',
	/**
	 * 绑定手机号
	 * @value bindmobile
	 */
	'BINDMOBILE': 'bindmobile',
	/**
	 * 邮箱忘记密码
	 * @value forgotpwd
	 */
	'EMAILFORGOTPWD': 'forgotpwd.email'
}
/**
 * 题类型
 */
export const QUESTIONTYPE = {
	/**
	 * 多选
	 * @value 2
	 */
	'MULTIPLECHOICE': 2,
	/**
	 * 单选
	 * @value 1
	 */
	'SINGLECHOICE': 1,
	/**
	 * 判断
	 * @value 3
	 */
	'JUDGE': 3
}
/**
 * 交卷类型
 */
export const SUBMISSIONTYPE = {
	/**
	 * 手动
	 * @value 1
	 */
	'MANUAL': 1,
	/**
	 * 自动交卷
	 * @value 2
	 */
	'AUTOMATIC': 2
}
/**
 * websock消息返回类型
 */
export const WEBSOCKTYPE = {
	/**
	 * 异地登录
	 * @value otherlogin
	 */
	'OTHERLOGIN': 'otherlogin',
	/**
	 * 提醒
	 * @value remind
	 */
	'REMIND': 'remind',
	/**
	 * 抓拍
	 * @value face
	 */
	'CAPTURE': 'face',
	/**
	 * 踢出考场
	 * @value evict
	 */
	'KICKOUT': 'evict',
	/**
	 * 作弊处理
	 * @value zuobi
	 */
	'ZUOBI': 'zuobi',
	/**
	 * 心跳响应
	 * @value heartbeat
	 */
	'HEARTBEAT': 'heartbeat',
	/**
	 * 论文呢支付成功
	 * @value paysucess
	 */
	'PAYSUCESS': 'paysucess'
}
/**
 * 开关ONANDOFF
 */
export const ONANDOFF = {
	/**
	 * 开
	 * @value 1
	 */
	'OPEN': '1',
	/**
	 * 关
	 * @value 2
	 */
	'SHUT': '2'
}
/**
 * 指导状态
 */
export const GUIDANCESTATUS = {
	/**
	 * 等待分配老师
	 * @value 0
	 */
	'TOBEALLOCATED': 0,
	/**
	 * 开题中
	 * @value 1
	 */
	'SUBJECT': 1,
	/**
	 * 论文大纲
	 * @value 2
	 */
	'OUTLINE': 2,
	/**
	 * 论文写作
	 * @value 3
	 */
	'FIRSTDRAFT': 3,
	/**
	 * 查重报告
	 * @value 4
	 */
	'FINALIZE': 4,
	/**
	 * 论文定稿
	 * @value 5
	 */
	'REPORT': 5,
	/**
	 * 完成论文(完成)
	 * @value 6
	 */
	'COMPLETE': 6,
	/**
	 * 评阅已分配(完成)
	 * @value 7
	 */
	'ASSIGNED': 7,
	/**
	 * 已评阅(完成)
	 * @value 8
	 */
	'REVIEWED': 8

}
/**
 * 审核状态
 */
export const AUDITSTATUS = {
	/**
	 * 已通过
	 * @value 2
	 */
	'PASSED': 2,
	/**
	 * 审核中
	 * @value 0
	 */
	'AUDITING': 0,
	/**
	 * 未通过
	 * @value 1
	 */
	'FAILED': 1
}
/**
 * 课程详情列表类型
 */
export const COURSEDETAILSLISTTYPE = {
	/**
	 * 课程目录
	 * @value 1
	 */
	'COURSECATALOG': 1,
	/**
	 * 阶段测验
	 * @value 2
	 */
	'FORMATIVETEST': 2,
	/**
	 * 答疑记录
	 * @value 3
	 */
	'ANSWERINGQUESTION': 3
}
/**
 * 直播状态
 */
export const LIVESTATUS = {
	/**
	 * 全部
	 * @value 0
	 */
	'WHOLELIVE': 0,
	/**
	 * 正在直播(进行中)
	 * @value 1
	 */
	'LIVEING': 1,
	/**
	 * 直播回顾(已结束)
	 * @value 2
	 */
	'CLOSED': 2
}

/**
 * 列表类型
 */
export const LISTTYPE = {
    // 点播课程
    'ONDEMANDCOURSES': 1,
    // 直播课程
    'LIVECOURSES': 2,
		// 选课中心
    'COURSESELECTION': 3,
		// 阶段测验记录
		'STAGETEST': 7,
		// 模拟测验记录
		'SIMULATIONTEXT': 5,
		// 答疑记录
		'QA': 6,
}
/**
 * 视频观看状态码
 */
export const VIDEOSTATUSCODE = {
	/**
	 * 今日学习时长已满
	 * @value 1
	 */
	'THEDURATIONISFULL': 1,
	/**
	 * 需要进行人脸识别
	 * @value 2
	 */
	'FACIALRECOGNITION': 2,
	/**
	 * 夜深了提示
	 * @value 3
	 */
	'LATENIGHTREMINDER': 3,
	/**
	 * 图片验证码
	 * @value 4
	 */
	'IMAGEVERIFICATION': 4,
	/**
	 * 进度记录人脸识别
	 * @value 5
	 */
	'PROGRESSRECORDFACE': 5,
	/**
	 * 图片验证和人脸识别都弹出
	 * @value 6
	 */
	'IMAGESANDFACES': 6, 
	/**
	 * 刷新课程
	 */
	'REFRESHCOURSE': 7,
	/**
	 * 跳到测试
	 * @value 0.1
	 */
	'GOTEST': 0.1,
	/**
	 * 跳到下一结
	 * @value 0.2
	 */
	'NEXTSECTION': 0.2,
	/**
	 * 恭喜您已全部学完
	 * @value 0.3
	 */
	'COMPLETEDLEARNING': 0.3

}
export const LISTTYPESTATUS = {
    /**
	 * 全部
	 * @value 0
	 */
	'WHOLELIVE': 0,
	/**
	 * 正在直播(未开始)
	 * @value 1
	 */
	'NOTSTARTED': 1,
	/**
	 * 正在直播(进行中)
	 * @value 2
	 */
	'LIVEING': 2,
	/**
	 * 直播回顾(已结束)
	 * @value 3
	 */
	'CLOSED': 3
}
/**
 * 列表类型
 */
export const TYPEFILTERING = {
    // 全部课程
    'ALLCOURSES': 0,
    // 正在学习
    'LEARNING': 1,
    // 答疑记录
    'ANSWERINGQUESTION': 3,
    // 阶段测试
    'FORMATIVETEST': 4,
		// 答疑记录
		'QA': 6,
		// 阶段测验记录
		STAGETEST: 7,
		// 模拟测验记录
		SIMULATIONTEXT: 5,
}

/**
 * 答辩状态
 */
export const DEFENSESTATUS = {
	/**
	 * 进入答辩(进行中)
	 * @value 进行中
	 */
	'INPROGRESS': '进行中',
	/**
	 * 等待答辩(未开始)
	 * @value 未开始
	 */
	'NOTSTARTED': '未开始',
	/**
	 * 答辩完成(已结束)
	 * @value 已结束
	 */
	'COMPLETED': '已结束'
}
/**
 * 订单类型
 */
export const ORDERTYPE = {
	/**
	 * 课程订单
	 * @value 1
	 */
	'COURSEORDERS': 1,
	/**
	 * 论文订单
	 * @value 2
	 */
	'PAPERORDER': 2
}
/**
 * 阅读状态
 */
export const READINGSTATUS = {
	/**
	 * 全部
	 * @value 0
	 */
	'WHOLE': 0,
	/**
	 * 未读
	 * @value 1
	 */
	'UNREAD': 1,
	/**
	 * 已读
	 * @value 2
	 */
	'READ': 2
}
/**
 *	视频操作列表类型
 */
 export const  VIDEOACTIONLISTTYPE = {
	/**
	 * 目录
	 * @value 1
	 */
	'CATALOGUE': 1,
	/**
	 * 答疑
	 * @value 2
	 */
	'ANSWER': 2
 }
 /**
 * 是否
 */
 export const WHETHER = {
	/**
	 * 是
	 * @value 0
	 */
	'YES': 0,
	/**
	 * 否
	 * @value 1
	 */
	'NO': 1
 }
  /**
 * 是否禁用
 */
  export const DISABLESTATUS = {
	/**
	 * 禁用
	 * @value 1
	 */
	'DISABLED': 1,
	/**
	 * 正常
	 * @value 0
	 */
	'NORMAL': 0
  }
/**
 * 考生类型
 */
export const CANDIDATETYPE  = {
	/**
	 * 社会考生
	 * @value 1
	 */
	'SOCIALCANDIDATES': 1,
	/**
	 * 集体考生
	 * @value 2
	 */
	'GROUPCANDIDATES': 2
}
/**
 * 缴费状态
 */
export const PAYMENTSTATUS = {
	/**
	 * 待缴费
	 * @value 0
	 */
	'PENDINGPAYMENT': 0,
	/**
	 * 已缴费（已通过）
	 * @value 3
	 */
	'PAID': 3,
	/**
	 * 审核中
	 * @value 1
	 */
	'UNDERREVIEW': 1,
	/**
	 * 未通过
	 * @value 2
	 */
	'NOTPASSED': 2
}
/**
 * 支付类型
 */
export const PAYMENTTYPE = {
	/**
	 * 论文支付
	 * @value 1
	 */
	'PAPERPAYMENT': 1,
	/**
	 * 自考助学培训费支付
	 * @value 2
	 */
	'TRAININGFEES': 2
}
/**
 * 记录操作类型
 */
export const OPERATIONTYPE = {
	/**
	 * 播放
	 * @value play
	 */
	'PLAY': 'play',
	/**
	 * 暂停
	 * @value pause
	 */
	'PAUSE': 'pause',
	/**
	 * 结束
	 * @value end
	 */
	'END': 'end',
	/**
	 * 播放中
	 * @value playtime
	 */
	'PLAYTIME': 'playtime',
	/**
	 * 暂停中
	 * @value pausetime
	 */
	'PAUSETIME': 'pausetime',
	/**
	 * 记录播放时间
	 * @value staytime
	 */
	'STAYTIME': 'staytime'
}
/**
 * 测试详情跳转逻辑
 */
export const TESTJUMPLOGIC = {
	/**
	 * 到课程详情
	 * @value 1
	 */
	'COURSEDETAILS': 1,
	/**
	 * 到视频详情
	 * @value 2
	 */
	'VIDEODETAILS': 2
	
}

/**
 * 从哪儿进入测验
 */
export const FROMWHERE = {
	/**
	 * 课程详情
	 * @value 1
	 */
	'COURSEDETAILS': 1,
	/**
	 * 视频详情
	 * @value 2
	 */
	'VIDEODETAILS': 2
}
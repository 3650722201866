/*
 * @Description: 全局字典
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */
import { LIVESTATUS, LISTTYPE, TYPEFILTERING, ORDERTYPE, READINGSTATUS } from "@/enum/index"
export default {
    state: {
        'READINGSTATUS': [
            {
                title: '全部',
                value: READINGSTATUS.WHOLE
            },
            {
                title: '未读',
                value: READINGSTATUS.UNREAD
            },
            {
                title: '已读',
                value: READINGSTATUS.READ
            }
        ],
        'ORDERTYPE': [
            {
                title: '课程订单',
                value: ORDERTYPE.COURSEORDERS
            },
            {
                title: '论文订单',
                value: ORDERTYPE.PAPERORDER
            }
        ],
        'LIVESTATUS': [
            {
                title: '全部直播',
                value: LIVESTATUS.WHOLELIVE
            },
            {
                title: '正在直播',
                value: LIVESTATUS.LIVEING
            },
            {
                title: '已结束',
                value: LIVESTATUS.CLOSED
            }
        ],
        'LISTTYPE': [
            {
                title: '点播课程',
                value: LISTTYPE.ONDEMANDCOURSES
            },
            {
                title: '直播课程',
                value: LISTTYPE.LIVECOURSES
            },
            {
                title: '阶段测验记录',
                value: LISTTYPE.STAGETEST
            },
            {
                title: '模拟测验记录',
                value: LISTTYPE.SIMULATIONTEXT
            },
            {
                title: '答疑记录',
                value: LISTTYPE.QA
            },
            // {
            //     title: '选课中心',
            //     value: LISTTYPE.COURSESELECTION
            // }
        ],
        'TYPEFILTERING': [
            {
                title: '全部课程',
                value: TYPEFILTERING.ALLCOURSES
            },
            {
                title: '正在学习',
                value: TYPEFILTERING.LEARNING
            },
            {
                title: '答疑记录',
                value: TYPEFILTERING.ANSWERINGQUESTION
            },
            {
                title: '测验记录',
                value: TYPEFILTERING.FORMATIVETEST
            }
        ]
    }
}
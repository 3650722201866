/*
 * @Description: 全局路由
 * @Version: 1.0.0
 * @Author: xuhao
 * @LastEditors: xuhao
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import { TOKEN_STORE_NAME, NOTMAINTAINED } from '@/config/setting';
import store from '@/store';
import { ONANDOFF } from "@/enum/index"
Vue.use(VueRouter);
const routes = [
    // 在这里定义你的路由配置
    {
      path: '/',
      name:'userViewsHomepage',
      component: () => import('@/views/userViews/homepage/index.vue'),
      redirect: '/portal',
      children: [
        {
          name: 'learningCenter',
          path:'/userViews/homepage/learningCenter',// 绝对路径
          component: () => import('@/views/userViews/homepage/learningCenter/index.vue'),
          children: [
            {
              name: 'examinationCenter',
              path:'/userViews/homepage/learningCenter/examinationCenter',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/examinationCenter/index.vue')
            },
            {
              name: 'accountSecurity',
              path:'/userViews/homepage/learningCenter/accountSecurity',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/accountSecurity/index.vue')
            },
            {
              name: 'courseCenter',
              path:'/userViews/homepage/learningCenter/courseCenter',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/courseCenter/index.vue')
            },
            {
              name: 'courseDetails',
              path:'/userViews/homepage/learningCenter/courseDetails',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/courseDetails/index.vue'),
              meta: {
                title: '课程详情'
              }
            },
            {
              name: 'thesisCenter',
              path:'/userViews/homepage/learningCenter/thesisCenter',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/thesisCenter/index.vue'),
              meta: {
                title: '课程详情'
              }
            },
            {
              name: 'thesisCenterDetails',
              path:'/userViews/homepage/learningCenter/thesisCenterDetails',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/thesisCenterDetails/index.vue'),
              meta: {
                title: '论文指导'
              }
            }, 
            {
              name: 'viewRecords',
              path:'/userViews/homepage/learningCenter/viewRecords',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/viewRecords/index.vue'),
              meta: {
                title: '查看记录'
              }
            },
            {
              name: 'confirmOrder',
              path:'/userViews/homepage/learningCenter/confirmOrder',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/confirmOrder/index.vue'),
              meta: {
                title: '确认订单'
              }
            },
            {
              name: 'defenseCenter',
              path:'/userViews/homepage/learningCenter/defenseCenter',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/defenseCenter/index.vue')
            },
            {
              name: 'orderCenter',
              path:'/userViews/homepage/learningCenter/orderCenter',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/orderCenter/index.vue')
            },
            {
              name: 'messageCenter',
              path:'/userViews/homepage/learningCenter/messageCenter',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/messageCenter/index.vue')
            },
            {
              name: 'homePage',
              path:'/userViews/homepage/learningCenter/homePage',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/homePage/index.vue')
            },
            {
              name: 'trainingFees',
              path:'/userViews/homepage/learningCenter/trainingFees',// 绝对路径
              component: () => import('@/views/userViews/homepage/learningCenter/trainingFees/index.vue')
            },
            // 错题列表
            {
              props: true,
              path: '/wrongQuestionRecord/:id',
              name: 'wrongQuestionRecord',
              component: () => import('@/views/userViews/homepage/learningCenter/wrongQuestionRecord/index.vue'),
            },
            // 错题汇总
            {
              props: true,
              path: '/wrongQuestionRecord',
              name: 'wrongQuestionRecord',
              component: () => import('@/views/userViews/homepage/learningCenter/wrongQuestionRecord/index.vue'),
            },
            // 答题卡
            {
              props: true,
              path: '/answerQuestions/:id',
              name: 'answerQuestions',
              component: () => import('@/views/userViews/homepage/learningCenter/answerQuestions/index.vue'),
            },
          ]
        },
        {
          path: '/portal',
          name:'homepageLive',
          component: () => import('@/views/userViews/homepage/homepageLive/index.vue'),
          meta: {
            notLogin: true
          }
        },
        {
          path: '/noticeAnnouncement',
          name:'noticeAnnouncement',
          component: () => import('@/views/userViews/homepage/noticeAnnouncement/index.vue'),
          meta: {
            title: ['当前位置', '通知公告'],
            notLogin: true
          }
        },
        {
          path: '/noticeDetails',
          name:'noticeDetails',
          component: () => import('@/views/userViews/homepage/noticeDetails/index.vue'),
          meta: {
            title: ['当前位置', '通知公告', '详情'],
            notLogin: true
          }
        },
        {
          path: '/policy',
          name:'policy',
          component: () => import('@/views/userViews/homepage/policy/index.vue'),
          meta: {
            title: ['当前位置', '自考政策'],
            notLogin: true
          }
        },
        {
          path: '/policyDetails',
          name:'policyDetails',
          component: () => import('@/views/userViews/homepage/policyDetails/index.vue'),
          meta: {
            title: ['当前位置', '自考政策', '详情'],
            notLogin: true
          }
        },
        {
          path: '/journalism',
          name:'journalism',
          component: () => import('@/views/userViews/homepage/journalism/index.vue'),
          meta: {
            title: ['当前位置', '自考新闻'],
            notLogin: true
          }
        },
        {
          path: '/journalismDetails',
          name:'journalismDetails',
          component: () => import('@/views/userViews/homepage/journalismDetails/index.vue'),
          meta: {
            title: ['当前位置', '自考新闻', '详情'],
            notLogin: true
          }
        },
        {
          path: '/helpCenter',
          name:'helpCenter',
          component: () => import('@/views/userViews/homepage/helpCenter/index.vue'),
          meta: {
            title: ['当前位置', '帮助中心'],
            notLogin: true
          }
        },
      ]
    },
    
    {
      path: '/reply',
      name: 'reply',
      component: () => import('@/views/userViews/reply/index.vue'),
    },
    {
      path: '/directSeeding',
      name: 'directSeeding',
      component: () => import('@/views/userViews/directSeeding/index.vue'),
    },
    {
      path: '/examination',
      name: 'examination',
      component: () => import('@/views/userViews/examination/index.vue'),
    },
    {
      path: '/videoModule',
      name: 'videoModule',
      component: () => import('@/views/userViews/videoModule/index.vue'),
    },
    {
      path: '/stageTest',
      name: 'stageTest',
      component: () => import('@/views/userViews/stageTest/index.vue'),
    },
    {
      path: '/stageTest2',
      name: 'stageTest2',
      component: () => import('@/views/userViews/stageTest2/index.vue'),
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: () => import('@/views/userViews/maintenance/index.vue'),
      meta: {
        notLogin: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/userViews/login/index.vue'),
      meta: {
        notLogin: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/userViews/register/index.vue'),
      meta: {
        notLogin: true
      }
    },
    {
      path: '/retrievePassword',
      name: 'retrievePassword',
      component: () => import('@/views/userViews/retrievePassword/index.vue'),
      meta: {
        notLogin: true
      }
    }
    
  ];

  const router = new VueRouter({
    mode: 'hash', // 使用 HTML5 history 模式，去掉URL中的 #
    routes,
  });

  router.beforeEach(async (to, from, next) => {
    const token = localStorage.getItem(TOKEN_STORE_NAME);
    if(!store.getters.globalStatus.config?.id){
      await Promise.all([store.dispatch('setConfig'), store.dispatch('setLink'), store.dispatch('getAgreement'), store.dispatch('getPolicy'), store.dispatch('setRepair'), store.dispatch('setRemind')])
    }
    if(store.getters.globalStatus.repair.is_enable === ONANDOFF.OPEN && !NOTMAINTAINED.includes(to.name)) {
      next({name: 'maintenance'})
      return
    }
    if((to.name === 'thesisCenter' && to.name === 'defenseCenter') && store.getters.globalStatus.major?.cengci === '专科') {
      next({name: 'examinationCenter'})
      return
    }
    if(token) {
      if(!store.getters.globalStatus.userInfo.id){
        await Promise.all([store.dispatch('setUserInfo')])
      }
      next()
    } else {
      if(to.meta.notLogin) {
        next()
      } else {
        next({name: 'login'})
      }
    }
  })
export default router;  
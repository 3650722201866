import request from '@/utils/request';
/**
 * 获取个人信息
 * @param params
 * @returns {Promise<*>}
 */
export async function getUserInfo(params) {
    const res = await request.get('/user/info', {
      params
    });
    if (res.data.code === 200) {
      return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
}
/**
 * 获取考籍信息
 * @param params
 * @returns {Promise<*>}
 */
export async function getMajor(params) {
    const res = await request.get('/user/major', {
      params
    });
    if (res.data.code === 200) {
      return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
}
/**
 * 发送验证码
 * @param params
 * @returns {Promise<*>}
 */
export async function sendCode(data) {
    const res = await request.post('/sms/send', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 发送邮箱验证码
 * @param params
 * @returns {Promise<*>}
 */
export async function sendEmail(data) {
    const res = await request.post('/email/send', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 上传
 * @param params
 * @returns {Promise<*>}
 */
export async function uploadFileImg(file) {
    const formData = new FormData();
    formData.append('images', file);
    const res = await request.post('/upload/images', formData);
    if (res.data.code === 200) {
      return res.data.data;
    }
    return Promise.reject(new Error(res.data.message));
  }
/**
 * 修改头像
 * @param params
 * @returns {Promise<*>}
 */
export async function editHeadimg(data) {
    const res = await request.post('/editHeadimg', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 修改头像
 * @param params
 * @returns {Promise<*>}
 */
export async function getConfig(data) {
    const res = await request.get('/get/config', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 友情链接
 * @param params
 * @returns {Promise<*>}
 */
export async function getLink(data) {
    const res = await request.get('/get/link', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 用户协议
 * @param params
 * @returns {Promise<*>}
 */
export async function getAgreement(data) {
  const res = await request.get('/get/agreement', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 隐私政策
 * @param params
 * @returns {Promise<*>}
 */
export async function getPolicy(data) {
  const res = await request.get('/get/policy', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

/**
 * 隐私政策
 * @param params
 * @returns {Promise<*>}
 */
export async function getCaptcha(data) {
  const res = await request.get('/get/captcha', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

/**
 * 获取考试数量
 * @param params
 * @returns {Promise<*>}
 */
export async function getExam(data) {
	const res = await request.get('/get/exam', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 网站维护
 * @param params
 * @returns {Promise<*>}
 */
export async function getRepair(data) {
	const res = await request.get('/get/repair', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
/**
 * 记录前端报错信息
 * @param params
 * @returns {Promise<*>}
 */
export async function errorLogWrite(data) {
	const res = await request.post('/error_log/write', data);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

/**
 * 记录前端报错信息
 * @param params
 * @returns {Promise<*>}
 */
export async function getHints(data) {
	const res = await request.get('/get/remind', { data });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 阶段测验记录
export async function getStageTest (payload, page, limit) {
	// const res = await request.get(`/course/jieduan/log?user_major_id=${payload}&page=${page}&limit=${limit}`);
	const res = await request.get(`/course/jieduan/log`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 模拟测验记录
export async function getSimulationTest (payload) {
	const res = await request.get(`/course/moni/log`, { params: payload });
	// const res = await request.get(`/course/moni/log?user_major_id=${payload}&page=${page}&limit=${limit}&course_id=${id}`);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 错题记录, 错题汇总
export async function getWrongQuestion (payload) {
	const res = await request.get(`/course/jieduan/log/error_log`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 错题记录, 错题汇总
export async function getMoniErrorLog (payload) {
	const res = await request.get(`/course/moni/log/error_log`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 阶段测验记录详情
export async function getStageTestDetail (payload) {
	const res = await request.get(`/course/jieduan/log/detail`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 获取单个题目
export async function getQuestion (payload) {
	const res = await request.get(`/course/jieduan/log/question`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 获模拟测验记录 取单个题目
export async function getMoniLogQuestion (payload) {
	const res = await request.get(`/course/moni/log/question`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 阶段测验列表
export async function getJieduanList (payload) {
	const res = await request.get(`/course/jieduan/index`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 阶段测验详情
export async function getJieduanDetail (payload) {
	const res = await request.get(`/course/jieduan/detail`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 点播课程 阶段测验 获取单个题目
export async function getJieduanQuestion (payload) {
	const res = await request.get(`/course/jieduan/question`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 点播课程 回答某道题
export async function getJieduanAnswer (payload) {
	const res = await request.post(`/course/jieduan/answer`, payload);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 阶段测验 提交接口
export async function getJieduanSubmit (payload) {
	const res = await request.post(`/course/jieduan/submit`, payload);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 模拟测验详情
export async function getMoniDetail (payload) {
	const res = await request.get(`/course/moni/detail`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 模拟测验记录详情
export async function getMoniLogDetail (payload) {
	const res = await request.get(`/course/moni/log/detail`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 点播课程 模拟测验 获取单个题目
export async function getMoniQuestion (payload) {
	const res = await request.get(`/course/moni/question`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 点播课程 模拟测验 回答某道题
export async function getMoniAnswer (payload) {
	const res = await request.post(`/course/moni/answer`, payload);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 模拟测验 提交接口
export async function getMoniSubmit (payload) {
	const res = await request.post(`/course/moni/submit`, payload);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 课程详情
export async function getCourseDetails (payload) {
	const res = await request.get(`/course/details`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 整体进度
export async function getCourseProgress (payload) {
	const res = await request.get(`/course/progress`, { params: payload });
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}

// 每分钟时间
export async function getCourseRecordTime (payload) {
	const res = await request.post(`/course/record_time`, payload);
	if (res.data.code === 200) {
		return res.data.data;
	}
	return Promise.reject(new Error(res.data.message));
}
